<template>
  <ContractsDetailTemplate />
</template>

<script>
import ContractsDetailTemplate from '@/components/customs/contracts/DetailTemplate.vue'

export default {
  components: {
    ContractsDetailTemplate,
  },

  props: {
    
  },
  computed: {

  },

  data() {
    return {}
  },

  watch: {

  },

  beforeMount () {

  },

  methods: {

  }
}
</script>
