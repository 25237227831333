<template>
  <ul>
    <li v-for="item in items" :key="item.id" :style="{ marginLeft: margin + 'px'}">
      <a 
        :href="'/contrat/' + item.id" 
        :class="['flex', 'flex-row', 'items-baseline', 'mb-0']"
        :style="{ textDecoration: 'none' }"
      >
        <div
          :style="{ maxWidth: '30px', margin: '0 10px 0 0' }" 
          :class="[level === 0 ? 'contract-parent--background' : null]"
        >
          <icon
            v-if="level === 0"
            icon="parentalite"
            size="sm"
          />
          <icon
            v-else
            icon="fleche-child"
            size="sm"
          />
        </div>
        <div
          :class="[level === 0 ? 'font-bold' : null]"
        >
          {{ item.title }}
        </div>
      </a>
      <ContractsRecursiveList
        v-if="item.childs && item.childs.length"
        :items="item.childs"
        :level="level + 1"
      />
    </li>
  </ul>
</template>
    
<script> 
export default {
  name: 'ContractsRecursiveList',

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    level: {
      type:  Number,
      required: true,
    }
  },

  computed: {
    margin(){
      return (this.level + 1) * 15
    }
  }
}
</script>