<template>
  <modal ref="addContractModal" :visible="isAddModalOpen" :close-action="null">
    <button
      :class="['btn', 'btn-secondary', 'my-10', 'text-center']"
      :style="{ maxWidth: '200px', margin: '20px auto'}"
      slot="activator"
    >
      + Ajouter un contrat
    </button>
    <div :class="['container', 'relative']">
      <section :class="['container-header', 'mb-5']">
        <h1 :class="['container-header--title--secondary']">
          <span :class="['bold']">
            + Ajouter un contrat
          </span>
        </h1>
        <hr :class="['absolute', 'w-full']" :style="{ top: '80px', left: '0', right: '0' }" />
      </section>

      <div class="p-10 text-center">
        En ajoutant le contrat, je lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur cursus quam risus, commodo sollicitudin lacus luctus ut. Nullam nec ante ut massa bibendum accumsan in nec enim. Suspendisse iaculis ipsum a volutpat scelerisque. Praesent nisl neque, dictum a lectus eget, hendrerit tincidunt nisl.
      </div>

      <div :class="['flex', 'items-center', 'row', 'justify-center']">
        <div v-for="option in options"
          :key="option.id"
          :class="['py-1', 'px-5', 'flex', 'flex-row', 'items-center']"
        >
          <input
            :key="option.id"
            :value="option.id"
            :id="option.id"
            v-model="typeContract"
            name="contracts"
            type="radio"
            :class="'cursor-pointer'"
          />
          <label :for="option.id" :class="['px-1', 'cursor-pointer']">
            {{ option.label }}
          </label>
        </div>
      </div>

      <div :class="['flex', 'items-center', 'row', 'justify-center', 'my-10']">
        <button
        :class="['btn', 'btn-outline--primary', 'mx-5']"
        @click="handleClose"
      >
        Annuler
      </button>
      <button
        :class="['btn', 'btn-secondary', 'mx-5', isSubmitCUShadowed ? 'disabled': null]"
        @click="submitCU"
      >
        Valider
      </button>
      </div>
    </div>
  </modal>
</template>
  
<script>
import $axios from "@/axios";
import Modal from '@/components/commons/Modal'
import SelectFilter from '@/components/commons/SelectFilter.vue'

export default {
  components: {
    Modal,
    SelectFilter,
  },

  data: () => ({
    isAddModalOpen: false,
    isCUChecked: false,
    typeContract: null,
    parentContracts: [],
  }),

  computed: {
    isSubmitCUShadowed () {
      return this.typeContract === 'amendment' && !this.parentContracts.length
    },
    options () {
      return [
        {
          id: 'contract',
          label: 'Créer un contrat'
        },
        {
          id: 'amendment',
          label: 'Créer un avenant'
        },
      ]
    }
  },

  beforeMount(){
    this.getStatusCU()

    this.parentContracts

    this.typeContract = this.options?.find((opt) => opt.id === 'contract')?.id
  },

  methods: {
    /**
     * Close action from parent
     * @return {void}
     */
    handleClose(){
      this.$refs.addContractModal.handleClose();
    },
    /**
     * Patch contracts Consent Action
     * @return {void}
     */
    async submitCU(){
      try {
        const request = await $axios.patch(
          `${process.env.VUE_APP_API_VERSION}/account-informations`,
          {
            contractsConsent: true
          }, {
            headers: {
              'X-CSRF-Token': this.$store.state.authentication.xcsrf_token
            }
          }
        )

        if (request.status === 200){
          this.isAddModalOpen = false
        }
      } catch (err) {
        console.error(err)
      } finally {}
    },
    /**
     * Get contracts Consent Status
     * @return {void}
     */
    async getStatusCU(){
      try {
        const response = await $axios.get(
          `${process.env.VUE_APP_API_VERSION}/account-informations`
        )

        if (response?.data?.contractsConsent){
          this.isAddModalOpen = false
        }
      } catch (err) {
        console.error(err)
      } finally {}
    }
  }
}
</script>