<template>
  <div :class="urgent ? 'contracts-listing__table__inner--urgent' : 'contracts-listing__table__inner'">
    <PrimeDatatable
      v-show="contracts.length"
      :value="contracts"
      :rowClass="() => 'contract'"
      @row-click="onRowClick"
    >
      <!-- Titre du Contrat -->
      <PrimeColumn
        :header="headerSorting ? 'Titre du contrat' : null"
        :headerClass="'contract-title'"
        bodyClass="contract-title__outer" 
      >
        <template #body="slotProps" >
          <div :class="'contract-title'">
            <div :class="'contract-title__inner'">
              <tag v-show="slotProps.data.amendment" :label="'Avenant'" />
              <span :class="'contract-title__inner__content'">{{ slotProps.data.title }}</span>
            </div>
          </div>
        </template>
      </PrimeColumn>
      <!-- Parentalité -->
      <PrimeColumn
        :header="headerSorting ? 'Parentalité' : null"
        bodyClass="contract-parent__outer" 
      >
        <template #body="slotProps" >
          <div
            v-if="slotProps.data.parent" 
            :class="['contract-parent--background']" 
            @click.stop="emitModalParentId(slotProps)"
          >
            <icon
              icon="parentalite"
              size="sm"
            />
          </div>
        </template>
      </PrimeColumn>
      <!-- Date d'application -->
      <PrimeColumn
        field="startDate"
        :header="headerSorting ? 'Date d\'application' : null"
        bodyClass="contract-date" 
      />
      <!-- Date de fin -->
      <PrimeColumn
        field="endDate"
        :header="headerSorting ? 'Date de fin' : null"
        bodyClass="contract-date" 
      />
      <!-- Date de prévenance -->
      <PrimeColumn
        :header="headerSorting ? 'Date de prévenance' : null"
        bodyClass="contract-date" 
      >
        <template #body="slotProps" >
          <div :class="!urgent ? 'contract-notificationsdate' : 'contract-notificationsdate--reminder'">
            {{ slotProps.data.notificationDate }}
          </div>
        </template>
      </PrimeColumn>
      <!-- Status -->
      <PrimeColumn
        :header="headerSorting ? 'Status' : null"
        bodyClass="contract-status" 
        >
        <template #body="slotProps" >
          <div 
            :class="['font-bold', 'text-center']"
            :style="{ color: statuses.find(item => item.value === slotProps.data.contractStatus).color }
          ">
            {{ statuses.find(item => item.value === slotProps.data.contractStatus).label }}
          </div>
        </template>
      </PrimeColumn>
    </PrimeDatatable>
  </div>
</template>
  
<script>
import Tag from '@/components/commons/Tag';

export default {
  components: {
    Tag
  },

  props: {
    contracts: {
      type: Array,
      default: () => [],
    },
    urgent: {
      type: Boolean, 
      default: false,
    },
    headerSorting: {
      type: Boolean,
      default: true,
    }
  },

  computed: {
    statuses(){
      return [
        {
          value: 'draft',
          label: 'Brouillon',
          color: 'grey',
        },
        {
          value: 'validated',
          label: 'Validé',
          color: '#015198', // blue
        },
        {
          value: 'active',
          label: 'Actif',
          color: '#92C03A',
        },
        {
          value: 'renew',
          label: 'Reconduit',
          color: '#92C03A',
        },
        {
          value: 'halted',
          label: 'Arrêté',
          color: '#E73128', // orange
        },
        {
          value: 'archived',
          label: 'Archivé',
          color: 'grey',
        }
      ]
    }
  },

  data() {
    return {
      variationTooltip: 'Compare la dernière valeur à la valeur précédente et affiche la variation en pourcentage et/ou en unité d’achat',
      januaryVariationTooltip: 'Compare la dernière valeur à la valeur au 1er Janvier de l’année en cours et affiche la variation en pourcentage et/ou en unité d’achat',
      yearVariationTooltip: 'Compare la dernière valeur à la valeur à la même date de l’année précédente et affiche la variation en pourcentage et/ou en unité d’achat',
      modalImage: {
        display: false,
        src: '',
        alt: '',
      }
    }
  },

  methods: {
    /**
     * emitModalParentId Handler
     * @param {object} props
     * @return {void}
     */
    emitModalParentId(props){
      this.$emit('emitModalParentId', props.data.id)
    },
    /**
     * onRow click action
     * @param {eventHandler} e
     * @return {void}
     */
    onRowClick(e) {
      this.$router.push({ name: 'contract-detail', params: { id: e.data.id }})
    }
  }
}
</script>