<template>
  <div
    class="select"
    v-click-outside="handleClose">
    <button
      class="btn btn-select"
      :class="{'disabled' : isEmpty}"
      @click="handleDropdown">
      <span :class="{'text-xs' : small}">
        {{ 'Sélectionner' }}
      </span>
      <icon
        class="transition-all duration-100 ease-in-out ml-3"
        :class="{'transform rotate-180' : isOpen}"
        icon="fleche-bas"
        size="xs"/>
    </button>

    <transition
      name="fade"
      mode="out-in"
    >
      <div
        class="select-dropdown"
        v-if="isOpen"
      >
        <ul class="select-list">
          <li
            v-for="(item, index) in options.items"
            :class="{
              'select-list--item': !item.subLabel,
              'mt-3': item.subLabel && index === 0
            }"
            :key="index">

            <strong
              v-if="item.subLabel"
              class="pl-3 cursor-pointer"
              @click="handleClickSublabel(item)"
            >
              {{ item.subLabel }}
            </strong>
            <ul v-if="item.subItems">
              <li
                class="select-list--item"
                v-for="(subItem, subIndex) in item.subItems"
                :key="subIndex">
                <input
                  class="cursor-pointer"
                  type="checkbox"
                  :id="subItem.value"
                  :value="subItem.value"
                  v-model="checked">
                <label
                  :for="subItem.value"
                  class="cursor-pointer">
                  {{ subItem.label }}
                </label>
              </li>
            </ul>

            <div
              v-else
              class="select-list--item">
              <input
                class="cursor-pointer"
                type="checkbox"
                :id="item.value"
                :value="item.value"
                v-model="checked">
              <label
                :for="item.value"
                class="cursor-pointer">
                {{ item.label }}
              </label>
            </div>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  props: {
    options: {
      type: Object,
      default: () => ({
        value: '', // Définit quelle variable filtrer
        label: '', // Texte du bouton affiché à l'user
        items: [ // Liste des choix
          {
            value: '', // Valeur du choix pour la requête
            label: '' // Texte du choix affiché à l'user
          }
        ]
      }),
    },

    small: {
      type: Boolean,
      default: false,
    }
  },

  data: () => ({
    isOpen: false,
    checked: []
  }),

  computed: {
    isEmpty () {
      return this.options.items?.length === 0
    }
  },

  methods: {
    handleClose () {
      this.isOpen = false
    },

    handleDropdown () {
      this.isOpen = !this.isOpen
    },

    handleClickSublabel (item) {
      this.checked = []
      for (const subItem of item.subItems) {
        this.checked.push(subItem.value)
      }
    }
  },

  directives: {
    ClickOutside
  }
}
</script>
