<template>
  <div class="contract-detail wrapper">
    <div class="container">
      <section class="container-header">
        <div class="container-header--title">
          <h1>{{ context === 'CREATE' ? '+ Ajout d\'un contrat' : header.title }}</h1>
        </div>
        
        <div :class="['subcontainer', 'subcontainer-large']">
          <nav :class="['flex', 'justify-content', 'container']" :style="{ flexDirection: 'row'}">
            <button
              class="btn btn-small btn-outline--primary-bluer inline-flex"
              @click="goToBack"
            >
              Retour
            </button>
            <div
              v-if="header.amendment || header.hasChildrens"
              :class="['flex', 'flex-row', 'items-center', 'cursor-pointer']"
              @click="openModalParent"
            >
              <div :class="['contract-parent--background']">
                <icon
                  icon="parentalite"
                  size="sm"
                />
              </div>
              <span :class="['text-primary-bluer', 'ml-2', 'underline']">
                {{ 
                  header.hasChildrens 
                  ? 'Parentalité du contrat' 
                  : 'Avenant de : ' + header.parentContractTitle
                }}
              </span>
            </div>
            <button
              class="btn btn-small btn-outline--primary-bluer inline-flex"
            >
              <icon
                icon="historique"
                size="xs"
                :class="['mr-2']"
              />
              Historique
            </button>
          </nav>
        </div>
      </section>
    </div>
    <div class="background">
      <div :class="['subcontainer-large', 'flex-col']">
        <div :class="['contracts-listing__table']">
          <nav :class="['flex', 'flex-row', 'items-center', 'justify-center', 'my-6']">
            <div :class="['flex', 'flex-row', 'cursor-pointer', 'mx-5']">
              <icon
                icon="modifier"
                size="sm"
              />
              <span :class="['ml-2', 'underline']">
                Modifier
              </span>
            </div>
            <div :class="['flex', 'flex-row', 'cursor-pointer', 'mx-5']"
            >
              <icon
                icon="poursuivre-contrat"
                size="sm"
              />
              <span :class="['ml-2', 'underline']">
                Poursuivre le contrat
              </span>
            </div>
            <div :class="['flex', 'flex-row', 'cursor-pointer', 'mx-5']"
            >
              <icon
                icon="supprimer"
                size="sm"
              />
              <span :class="['ml-2', 'underline']">
                Supprimer
              </span>
            </div>
          </nav>

          <card>
            <!-- <contact :contact="contact" /> -->
             <form action="#">
                <!-- INFORMATIONS GÉNÉRALES -->
                <section :class="['relative']">
                  <header
                    class="card-header flex flex-col px-8 md:flex-row items-center p-6 justify-center"
                    :style="{ boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.05)' }"
                  >
                    <h2 
                      :class="['m-0-auto', 'text-secondary', 'font-bold']"
                    >
                      Informations générales
                    </h2>
                    <span
                      v-if="header.author"
                      :class="['font-normal', 'absolute']" :style="{ right: '26px' }"
                    >
                      Auteur: {{ header.author }}
                    </span>
                  </header>
                  <fieldset class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4 mx-8">
                    <!-- Satus -->
                    <div class="flex flex-col">
                      <label
                        for="status"
                        :class="['form-label', 'font-bold']"
                      >
                        Status
                      </label>
                      <m-select
                        :options="statuses"
                        :style="{marginTop: '.75rem'}"
                      />
                    </div>

                    <!-- Title -->
                    <div class="flex flex-col">
                      <label
                        for="title"
                        :class="['form-label', 'font-bold']"
                      >
                        Titre
                      </label>
                      <input
                        id="title"
                        name="title"
                        v-model="form.title"
                        placeholder="Titre du contrat"
                        type="text"
                        class="form-input"
                      />
                    </div>

                    <!-- Cadre et Contrat Parent -->
                    <div :class="['flex', 'flex-col', 'justify-between']">
                      <!-- Cadre -->
                      <div class="flex flex-col">
                        <label
                          for="cadre"
                          :class="['form-label', 'font-bold']"
                        >
                          S’agit-il d’un contrat cadre ?
                        </label>
                        <span>{{ form.masterContract ? 'Oui' : 'Non' }}</span>
                      </div>
                      <!-- Contrat parent -->
                      <div class="flex flex-col">
                        <label
                          for="parent-contract"
                          :class="['form-label', 'font-bold']"
                        >
                          Contrat parent
                        </label>
                        <span>{{ header.parentContractTitle }}</span>
                      </div>
                    </div>

                    <!-- Description -->
                    <div class="flex flex-col">
                      <label
                        for="description"
                        :class="['form-label', 'font-bold']"
                      >
                        Description
                      </label>
                      <textarea
                        id="description"
                        name="description"
                        v-model="form.description"
                        rows="5"
                        type="textfield"
                        :class="['form-input']"
                      >
                      </textarea>
                    </div>
                    
                    <!-- Documents contractuels (contrats et annexes)  -->
                    <div class="flex flex-col">
                      <label
                        for="documents"
                        :class="['form-label', 'font-bold']"
                      >
                        Documents contractuels (contrats et annexes)
                      </label>
                      <ul v-if="form.contractDocuments.length">
                        <li v-for="(item, index) in form.contractDocuments" :key="index">
                          {{ item.filename }}
                        </li>
                      </ul>
                    </div>

                    <!-- Collaborateur(s) assigné(s) au contrat  -->
                    <div class="flex flex-col">
                      <label
                        for="collaborators"
                        :class="['form-label', 'font-bold']"
                      >
                        Collaborateur(s) assigné(s) au contrat
                      </label>
                      <ul v-if="form.collaborators.length">
                        <li v-for="(item, index) in form.collaborators" :key="index">
                          {{ item.name }}
                        </li>
                      </ul>
                    </div>

                    <!-- Pièces jointes privées, non incluses dans le contrat  -->
                    <div class="flex flex-col">
                      <label
                        for="pj"
                        :class="['form-label', 'font-bold']"
                      >
                        Pièces jointes privées, non incluses dans le contrat
                      </label>
                      <ul v-if="form.privateAttachments.length">
                        <li v-for="(item, index) in form.privateAttachments" :key="index">
                          {{ item.filename }}
                        </li>
                      </ul>
                    </div>

                    <div class="flex flex-col"></div>

                    <!-- Nature du contrat  -->
                    <div class="flex flex-col">
                      <label
                        for="nature"
                        :class="['form-label', 'font-bold']"
                      >
                        Nature du contrat
                      </label>
                      <input
                        id="nature"
                        name="nature"
                        v-model="form.contractNature"
                        placeholder="Nature du contrat"
                        type="text"
                        class="form-input"
                      />
                    </div>

                    <!-- Univers d'achat  -->
                    <div class="flex flex-col">
                      <label
                        for="purchase-category"
                        :class="['form-label', 'font-bold']"
                      >
                        Univers d’achat
                      </label>
                      <input
                        id="purchase-category"
                        name="purchase-category"
                        v-model="form.purchaseCategory"
                        placeholder="Univers d'achat"
                        type="text"
                        class="form-input"
                      />
                    </div>

                    <!-- Réseau Mercurial -->
                    <div class="flex flex-col">
                      <label
                        for="mercunetwork"
                        :class="['form-label', 'font-bold']"
                      >
                        Ce contrat est-il lié au réseau Mercurial ?
                      </label>
                      <span>{{ form.mercurialNetwork ? 'Oui' : 'Non' }}</span>
                    </div>

                    <!-- Fournisseur -->
                    <div class="flex flex-col">
                      <label
                        for="supplierText"
                        :class="['form-label', 'font-bold']"
                      >
                        Fournisseur
                      </label>
                      <input
                        id="supplierText"
                        name="supplierText"
                        v-model="form.supplierText"
                        placeholder="Fournisseur"
                        type="text"
                        class="form-input"
                      />
                    </div>
                  </fieldset>
                  <br><br>
                </section>
                <!-- DATES ET ÉCHÉANCES -->
                <section>
                  <header
                    class="card-header flex flex-col px-8 md:flex-row items-center p-6 justify-center"
                    :style="{ boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.05)' }"
                  >
                    <h2 
                      :class="['m-0-auto', 'text-secondary', 'font-bold']"
                    >
                      Dates et échéances
                    </h2>
                  </header>
                  <fieldset class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4 mx-8">
                    <!-- Date d'application -->
                    <div class="flex flex-col">
                      <label
                        for="startDate"
                        :class="['form-label', 'font-bold']"
                      >
                        Date d’application
                      </label>
                      <Calendar
                        v-model="form.startDate"
                        dateFormat="dd/mm/yyyy"
                        show-icon
                        placeholder="Date d’application"
                        class="w-full mt-6"
                        showButtonBar
                      />
                    </div>

                    <!-- Date de fin -->
                    <div class="flex flex-col">
                      <label
                        for="endDate"
                        :class="['form-label', 'font-bold']"
                      >
                        Date de fin
                      </label>
                      <Calendar
                        v-model="form.endDate"
                        dateFormat="dd/mm/yyyy"
                        show-icon
                        placeholder="Date de fin"
                        class="w-full mt-6"
                        showButtonBar
                      />
                    </div>

                    <!-- Durée de préavis en jour -->
                    <div class="flex flex-col">
                      <label
                        for="noticeDuration"
                        :class="['form-label', 'font-bold']"
                      >
                        Titre
                      </label>
                      <input
                        id="noticeDuration"
                        name="noticeDuration"
                        v-model="form.noticeDuration"
                        placeholder="Titre du contrat"
                        type="text"
                        class="form-input"
                      />
                    </div>

                    <!-- Date de prévenance (notification) -->
                    <div class="flex flex-col">
                      <label
                        for="notificationDate"
                        :class="['form-label', 'font-bold']"
                      >
                        Date de prévenance (notification)
                      </label>
                      <Calendar
                        v-model="form.notificationDate"
                        dateFormat="dd/mm/yyyy"
                        show-icon
                        placeholder="Date de prévenance (notification)"
                        class="w-full mt-6"
                        showButtonBar
                      />
                    </div>

                    <!-- Reconduction tacite -->
                    <div class="flex flex-col">
                      <label
                        for="automaticRenewal"
                        :class="['form-label', 'font-bold']"
                      >
                        Reconduction tacite
                      </label>
                      <span>{{ form.automaticRenewal ? 'Oui' : 'Non' }}</span>
                    </div>

                    <!-- Durée de reconduction -->
                    <div class="flex flex-col">
                      <label
                        for="renewalDuration"
                        :class="['form-label', 'font-bold']"
                      >
                        Durée de reconduction en mois
                      </label>
                      <input
                        id="renewalDuration"
                        name="renewalDuration"
                        v-model="form.renewalDuration"
                        placeholder="Durée de reconduction en mois"
                        type="text"
                        class="form-input"
                      />
                    </div>
                  </fieldset>
                  <br><br>
                </section>
                
                <!-- DÉTAILS DU CONTRAT -->
                <section>
                  <header
                    class="card-header flex flex-col px-8 md:flex-row items-center p-6 justify-center"
                    :style="{ boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.05)' }"
                  >
                    <h2 
                      :class="['m-0-auto', 'text-secondary', 'font-bold']"
                    >
                      Détails du contrat
                    </h2>
                  </header>
                  <fieldset class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4 mx-8">
                    <!-- Montant du contrat en euros -->
                    <div class="flex flex-col">
                      <label
                        for="yearAmount"
                        :class="['form-label', 'font-bold']"
                      >
                        Montant du contrat en euros
                      </label>
                      <input
                        id="yearAmount"
                        name="yearAmount"
                        v-model="form.yearAmount"
                        placeholder="Montant du contrat en euros"
                        type="text"
                        class="form-input"
                      />
                    </div>

                    <!-- Devise -->
                    <div class="flex flex-col">
                      <label
                        for="currency"
                        :class="['form-label', 'font-bold']"
                      >
                        Devise
                      </label>
                      <input
                        id="currency"
                        name="currency"
                        v-model="form.currency"
                        placeholder="Devise"
                        type="text"
                        class="form-input"
                      />
                    </div>

                    <!-- Clause de révision + Réference interne -->
                    <div :class="['flex', 'flex-col', 'justify-between']">
                      <!-- Clause de révision -->
                      <div class="flex flex-col">
                        <label
                          for="pricesRevisionClause"
                          :class="['form-label', 'font-bold']"
                        >
                          Ce contrat inclut-il une clause de révision des prix ?
                        </label>
                        <span>{{ form.pricesRevisionClause ? 'Oui' : 'Non' }}</span>
                      </div>
                      <!-- Référence interne -->
                      <div class="flex flex-col">
                        <label
                          for="internalRef"
                          :class="['form-label', 'font-bold']"
                        >
                          Référence interne
                        </label>
                        <input
                          id="internalRef"
                          name="internalRef"
                          v-model="form.internalRef"
                          placeholder="Référence interne"
                          type="text"
                          class="form-input"
                        />
                      </div>
                    </div>

                    <!-- Note -->
                    <div class="flex flex-col">
                      <label
                        for="note"
                        :class="['form-label', 'font-bold']"
                      >
                        Note
                      </label>
                      <textarea
                        id="note"
                        name="note"
                        v-model="form.note"
                        rows="5"
                        type="textfield"
                        :class="['form-input']"
                      >
                      </textarea>
                    </div>
                    
                  </fieldset>
                  <br><br>
                </section>
             </form>

            <!-- <template
              slot="footer">
              <button
                class="btn btn-secondary btn-block"
                @click="handleClick">Prendre contact</button>
            </template> -->
          </card>
        </div>
      </div>
    </div>

    <ContractsModalParent
      :isParentModalOpen.sync="isParentModalOpen"
      :id="refId"
      @closeModalParent="isParentModalOpen = false"
    />  
  </div>
</template>

<script>
import ContractsModalParent from "@/components/customs/contracts/ModalParent.vue";
import Card from '@/components/commons/Card.vue'
import $axios from "@/axios";
import MSelect from '@/components/commons/Select.vue'

export default {
  components: {
    ContractsModalParent,
    Card,
    MSelect,
  },
  props: {
    refId: {
      type: Number | String | null,
      default: null
    }
  },
  data() {
    return {
      form: {
        automaticRenewal: true,
        collaborators: [],
        contractDocuments: [],
        contractNature: null,
        contractStatus: null,
        currency: null,
        description : null,
        endDate: null,
        internalRef: null,
        masterContract: false,
        mercurialNetwork: false,
        note: null, 
        noticeDuration: null,
        notificationDate: null,
        parentContract: null,
        pricesRevisionClause: false,
        privateAttachments: [],
        purchaseCategory: null,
        renewalDuration: null,
        startDate: null,
        supplier: null,
        supplierText: null,
        title: null,
        yearAmount: null,
      },
      header: {
        title: null,
        author: null,
        amendment: false,
        parentContractTitle: null,
        hasChildrens: true
      },
      isParentModalOpen: false,
    }
  },
  computed: {
    context(){
      return this.refId ? 'VIEW' : 'CREATE'
    },
    statuses(){
      return {
        value: 'status',
        label: 'Status',
        items: [
          {
            value: 'draft', // Valeur du choix pour la requête
            label: 'Brouillon' // Texte du choix affiché à l'user
          },
          {
            value: 'archive', // Valeur du choix pour la requête
            label: 'Archivé' // Texte du choix affiché à l'user
          }
        ]
      }
    }
  },
  mounted() {
    this.fetchContract()
  },

  methods: {
    /**
     * openModalParent Action
     * @return {void}
     */
    openModalParent(){
      this.isParentModalOpen = true;
    },
    /**
     * closeModalParent Action
     * @param {object} data
     * @return {void}
     */
    closeModalParent(){
      this.isParentModalOpen = true;
    },
    /**
     * fetch Contract function
     * @return {void}
     */
    async fetchContract(){
      try {
        const response = await $axios.get(`${process.env.VUE_APP_API_VERSION}/contract/${this.refId}`,
          {
          headers: {
            'X-CSRF-Token': this.$store.state.authentication.xcsrf_token
          }
        })

        Object.assign(this, response.data);
      } catch (error) {
        console.log('lezf')
      }
    },
    /**
     * Go to back action
     * @return {void}
     */
    goToBack(){
      this.$router.push({ name: 'contracts' })
    }
  }
}
</script>
